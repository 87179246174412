

footer{
    position: relative;
    bottom: 0;
    color: #fff;
    height: fit-content;
}

footer a{
    text-decoration: none;
    color: #fff;
    transition: color 0.1s ease-out;
    &:hover{
        color: #a01aff;
    }
}

