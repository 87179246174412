.ProcessStep {
    padding: 0px 10px;
}

.process-content {
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 25px;
    height: 65vh;
    min-height: fit-content;
    padding: 30px 5px;
}

.process-step {
    margin: 40px auto 30px auto;
}

.process-step .ProcessStep:nth-child(odd) {
    background: #e5d7f0;
}

.steps {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.steps>div {
    display: flex;
    gap: 10px;
    width: fit-content;
}




/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {
    .process-content {
        width: 80%;
    }
}

/* Mobile */
@media (max-width: 768px) {

    .process-content {
        width: 95%;
    }
    .steps>div {
        flex-direction: column;
    }
}