
.contacts-details{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin: 70px auto 100px auto;
    width: 90%;
}

.contacts-details > div{
    color: #000;
    display: flex;
    gap: 10px;
    min-width: 300px;
}

.contacts-details a{
    text-decoration: none;
    color: #000;
    font-weight: 400;
}


.contacts-details img{
    width: 60px;
    padding-right: 15px;
}

.address{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.address b{
    display: flex;
    gap: 15px;
}