
.contact-form-content {
    width: 70%;
    margin: 70px auto;
    flex-direction: column;
    gap: 15px;
    font-weight: 200;
    letter-spacing: 1px;
}

.contact-form{
    width: 100%;
    background: #fff;
    padding: 10px 30px;
    border-radius: 4px;
    color: #000;
    overflow: hidden;
    background: #fff;
    padding: 30px;
    border-radius: 4px;
    border: 1px solid #6a0dad52;
    box-shadow: 0 4px 10px 0 #6a0dad52, 0 6px 15px 0 #a01aff4e;
}


.contact-form form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.contact-form form .general{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

input, select, textarea{
    padding: 11px;
    border-radius: 3px;
    background: #eee;
    outline: none;
    border: none;
    width: 100%;
}
.message{
    display: flex;
    flex-direction: column;
}
.contact-form label{
    text-align: left;
    font-weight: 500;
    padding: 5px;
    padding-left: 0;
}

.submit-button.contact{
    text-align: center;
}


/* Mobile */
@media (max-width: 768px) {
    .contact-form-content {
        width: 95%;
    }
    .contact-form form .general{
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}