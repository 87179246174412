/* FAQSection.module.css */
.faqSection {
    padding: 6rem 2rem;
    background: white;
}

.faqSection h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #1a365d;
    margin-bottom: 3rem;
}

.faqGrid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.faqItem {
    padding: 2rem;
    background: #f7fafc;
    border-radius: 12px;
    transition: transform 0.3s ease;
}

.faqItem:hover {
    transform: translateY(-5px);
}

.faqItem h3 {
    font-size: 1.25rem;
    color: #1a365d;
    margin-bottom: 1rem;
    position: relative;
}

.faqItem p {
    color: #4a5568;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .faqGrid {
        grid-template-columns: 1fr;
    }

    .faqSection h2 {
        font-size: 2rem;
    }

    .faqItem {
        padding: 1.5rem;
    }
}

/* Animation classes */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.faqItem {
    animation: fadeIn 0.5s ease-out forwards;
}

.faqItem:nth-child(1) {
    animation-delay: 0.1s;
}

.faqItem:nth-child(2) {
    animation-delay: 0.2s;
}

.faqItem:nth-child(3) {
    animation-delay: 0.3s;
}

.faqItem:nth-child(4) {
    animation-delay: 0.4s;
}