.projectsContainer {
    width: 90%;
    padding: 20px;
    margin: 40px auto;
}

.projectsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    justify-content: center;
}

.projectCard {
    position: relative;
    border-radius: 5px;
    text-decoration: none;
    color: #000;
    border: 1px solid #6a0dad52;
    box-shadow: 0 4px 10px 0 #6a0dad52, 0 6px 15px 0 #a01aff4e;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background: #fff;
    padding: 20px;
}

.projectCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px 0 #6a0dad52, 0 8px 20px 0 #a01aff4e;
}

.projectContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.projectContent h3 {
    color: #6A0DAD;
    font-size: 2rem;
    margin: 0;
    text-align: center;
}

.projectContent p {
    margin: 0;
    line-height: 1.6;
    font-size: 0.95rem;
    text-align: justify;
}

.tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: auto;
}

.tag {
    background: rgba(106, 13, 173, 0.1);
    color: #6A0DAD;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
}

@media (max-width: 768px) {
    .projectsContainer {
        width: 95%;
        padding: 10px;
    }

    .projectContent h3 {
        font-size: 1.75rem;
    }
}

@media (max-width: 1024px) and (min-width: 769px) {
    .projectsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}