

.Heading{
    flex-direction: column;
    padding: 25px;
    width: 70%;
    margin: 70px auto 10px auto;
    gap: 10px;
}


/* Mobile */
@media (max-width: 768px) {
    .Heading{
        width: 95%;
    }
}


