.Goals {
    width: 90%;
    margin: auto;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: minmax(250px, auto);
    justify-content: center;
    gap: 30px;
    color: #000;
    height: fit-content;
    min-height: 400px;
    flex-wrap: wrap;
}

.Goals>div {
    background: #fff;
    padding: 50px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: fit-content;
    border: 1px solid #6a0dad52;
    min-height: 250px;
    height: 70%;
    box-shadow: 0 4px 10px 0 #6a0dad52, 0 6px 15px 0 #a01aff4e;
    width: 100%;
    min-width: 200px;
}

.Goals div div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: justify;
}

.Goals h3 {
    color: #6A0DAD;
}

.Goals img {
    width: 80px;
}

/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {

    .Goals {
        width: 90%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-auto-rows: minmax(250px, auto);
        gap: 30px;
        min-height: 400px;
    }

    .Goals>div {
        background: #fff;
        padding: 50px 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        width: fit-content;
        border: 1px solid #6a0dad52;
        min-height: 250px;
        height: 70%;
        box-shadow: 0 4px 10px 0 #6a0dad52, 0 6px 15px 0 #a01aff4e;
        width: 100%;
        min-width: 200px;
    }
}

/* Mobile */
@media (max-width: 768px) {
    /* Styles for mobile devices */
}

/* Mobile Landscape */
@media (orientation: landscape) and (max-width: 768px) {
    /* Styles for mobile devices in landscape orientation */
}