.Home {
    position: absolute;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
}


.Home .content {
    z-index: 1;
    color: white;
    padding: 20px;
    margin: auto;
}


.repeating {
    font-size: 2.2em;
    color: yellow;
    height: 50px;
}

.cursor {
    animation: blink-animation 1s step-end infinite;
}

@keyframes blink-animation {
    50% {
        opacity: 0;
    }
}

.home-btns {
    display: flex;
    gap: 20px;
}


/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {
    .Home .content {
        margin-top: 120px;
    }
}

/* Mobile */
@media (max-width: 768px) {
    .Home .content {
        margin-top: 140px;
    }
    .repeating {
        font-size: 1.8em;
        color: yellow;
        height: 60px;
    }
}

/* Mobile Landscape */
@media (orientation: landscape) and (max-width: 768px) {
    /* Styles for mobile devices in landscape orientation */
}