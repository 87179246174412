

.Mid{
    padding: 100px 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
    background: #3b3a42;
    flex-wrap: wrap;
}


.Mid > div{
    width: 22%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 200px;
    align-items: center;
}

.Mid h3{
    padding: 10px 0;
    text-transform: uppercase;
}
.contacts-f,
.useful-links-f{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start !important;
}

.more-services-f,.services-f{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.more-services-f svg:hover path{
    stroke: none;
    fill: #a01aff;
} 

.more-services-f a:last-child svg{
    margin-left: -3px;
}

.more-services-f a:last-child svg:hover path:nth-child(2){
    fill: #3b3a42;
}

.more-services-f .links{
    display: flex;
    gap: 6px;    
}

.more-services-f.discover a{
    color: yellow;
    &:hover{
        color: #fff;
    }
}

.last{
    gap: 30px !important;
}
.last >div{
    width: 70%;
}

/* Mobile */
@media (max-width: 768px) {
    .Mid{
        padding: 10px 5px 30px 5px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .Mid > div{
        width: 100%;
        flex-direction: column;
        gap: 8px;
        min-width: 150px;
    }
    .last{
        gap: 20px !important;
    }
    .last >div{
        width: 100%;
    }
}