@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}


body {
  font-size: 15px;
  overflow-x: hidden;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-content {
  width: 65%;
  margin: auto;
  flex-direction: column;
  gap: 40px;
  font-weight: 200;
  letter-spacing: 1px;
}

.shineOnHover {
  cursor: pointer;
  background: #6a0dad;
  color: white;
  transition: all 0.5s;

  &:hover {
    background: #a01aff;
  }
}

/* FFBF8E */

.shineOnHover::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -80%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(45deg);
  transition: all 0.5s;
  opacity: 0;
}

.shineOnHover:hover::before {
  left: 100%;
  opacity: 1;
  transition: all 0.5s;
}


.button {
  padding: 15px 30px;
  cursor: pointer;
  text-transform: uppercase;
  border: 0;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  font-weight: 400;
}

.shine {
  cursor: pointer;
  background: #6A0DAD;
  color: white;
  transition: all 0.5s;

  &:hover {
    background: #a01aff;
  }
}
/* FFB67E skin color */

.shine::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -80%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(45deg);
  transition: all 0.5s;
  opacity: 0;
}

.shine:hover::before {
  left: 100%;
  opacity: 1;
  transition: all 0.5s;
}

.fade-in {
  transform: translateY(150px);
  opacity: 0;
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

.fade-in.in-view {
  transform: translateY(0);
  opacity: 1;
}

.seperator-line {
  width: 50px;
  height: 3px;
  background: #6A0DAD;
}

p {
  line-height: 20px;
}

/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {

  .home-content {
    width: 70%;
  }

  .button {
    padding: 12px 25px;
  }
}

/* Mobile */
@media (max-width: 768px) {

  html,body{
    overflow-x: hidden;
  }

  .home-content {
    width: 90%;
  }

  .button {
    padding: 13px 20px;
    font-size: 0.8em;
  }
  .hide-on-phone{
    display: none !important;
  }
}

/* Mobile Landscape */
@media (orientation: landscape) and (max-width: 768px) {
  /* Styles for mobile devices in landscape orientation */
}


