.featuresSection {
    padding: 2rem;
    background: linear-gradient(135deg, #142952 0%, #142952 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.tvStand {
    width: 100%;
    display: flex;
    justify-content: center;
    perspective: 1000px;
    margin-bottom: 4rem;
    padding-top: 2rem;
}

.tvContainer {
    width: 40%;
    max-width: 1000px;
    transform: rotateX(5deg);
    transform-style: preserve-3d;
}

.featurePreview {
    position: relative;
    border-radius: 20px;
    background: #1a1a1a;
    padding: 20px;
    box-shadow:
        0 0 0 2px rgba(255, 255, 255, 0.1),
        0 20px 40px rgba(0, 0, 0, 0.4),
        0 0 100px rgba(74, 158, 255, 0.2);
    transform-style: preserve-3d;
}

.previewContainer {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background: #ffffff;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.previewContainer.transitioning {
    opacity: 0.7;
    filter: blur(2px);
}

.previewMedia {
    width: 100%;
    display: block;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.featuresContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.pyramidContainer {
    perspective: 1000px;
    padding-bottom: 2rem;
}

.features {
    display: flex;
    justify-content: center;
    gap: 2rem;
    transform-style: preserve-3d;
    transform: rotateX(25deg);
}

.feature {
    flex: 1;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 12px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
}

.feature:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-8px);
}

.activeFeature {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow:
        0 0 30px rgba(74, 158, 255, 0.3),
        0 0 60px rgba(74, 158, 255, 0.1);
}

.featureIcon {
    color: #4a9eff;
    width: 36px;
    height: 36px;
    filter: drop-shadow(0 0 10px rgba(74, 158, 255, 0.5));
}

.featureContent {
    text-align: center;
}

.featureTitle {
    font-size: 1.25rem;
    color: #ffffff;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.featureDescription {
    color: #e2e8f0;
    line-height: 1.5;
    font-size: 0.925rem;
}

.connectorSvg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
}

.connector {
    fill: none;
    stroke: url(#connectorGradient);
    stroke-width: 3;
    stroke-linecap: round;
    filter: url(#connectorGlow);
    opacity: 1;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    vector-effect: non-scaling-stroke;
}

.connector.loading {
    animation: loadingPulse 1.5s ease-in-out infinite;
}

@keyframes loadingPulse {
    0% {
        stroke-width: 3;
        filter: url(#connectorGlow);
    }

    50% {
        stroke-width: 5;
        filter: url(#connectorGlow) drop-shadow(0 0 8px rgba(74, 158, 255, 0.8));
    }

    100% {
        stroke-width: 3;
        filter: url(#connectorGlow);
    }
}

@media (max-width: 1024px) {
    .tvContainer {
        width: 95%;
    }

    .features {
        flex-direction: column;
        align-items: center;
        transform: none;
        gap: 1rem;
    }

    .feature {
        max-width: 100%;
        width: 100%;
    }

    .connector {
        display: none;
    }
}

@media (max-width: 768px) {
    .featuresSection {
        padding: 1rem;
    }

    .feature {
        padding: 1.5rem;
    }

    .featureTitle {
        font-size: 1.125rem;
    }
}