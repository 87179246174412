/* these root colors are for presentproai */
:root {
  /* Primary Brand Colors */
  --primary-dark: #1a365d;
  --primary-darker: #142952;
  --primary-light: #2d3748;
  --primary-lighter: #3a4659;

  /* Background Gradients */
  --gradient-primary: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary-light) 100%);
  --gradient-text: linear-gradient(90deg, #ffffff 0%, #e2e8f0 100%);

  /* Neutral Colors */
  --neutral-50: #f7f9fc;
  --neutral-100: #e2e8f0;
  --neutral-200: #cbd5e0;
  --neutral-300: #a0aec0;
  --neutral-400: #4a5568;
  --neutral-500: #2d3748;
  --neutral-600: #1a202c;

  /* Semantic Colors */
  --error-bg: #fff5f5;
  --error-border: #fed7d7;
  --error-text: #c53030;

  /* Box Shadows */
  --shadow-sm: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-focus: 0 0 0 3px rgba(66, 153, 225, 0.15);

  /* State Colors */
  --state-hover: #2c5282;
  --state-disabled: #cbd5e0;
}

/* the above root colors are for presentproai */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}