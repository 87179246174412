.container {
    min-height: 100vh;
    background-color: #f7f9fc;
    width: 100%;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    width: 100%;
}

.header {
    margin-bottom: 2rem;
}

.header h1 {
    color: #1a365d;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.header p {
    color: #4a5568;
    font-size: 1rem;
    line-height: 1.5;
}

.section {
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    border: 1px solid #e2e8f0;
}

.section h2 {
    color: #1a365d;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.section p {
    color: #4a5568;
    line-height: 1.6;
    margin-bottom: 1rem;
    font-size: 1rem;
}

.section ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.section li {
    color: #4a5568;
    line-height: 1.6;
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
    position: relative;
    font-size: 1rem;
}

.section li::before {
    content: "•";
    color: #4299e1;
    position: absolute;
    left: 0;
}

.contactSection {
    background-color: #f8fafc;
    border: 1px solid #e2e8f0;
}

@media (max-width: 768px) {
    .content {
        padding: 1rem;
    }

    .section {
        padding: 1.25rem;
        margin-bottom: 1rem;
    }
}