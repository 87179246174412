header {
    position: fixed;
    top: 0;
    display: grid;
    grid-template-columns: 25% 80% 20%;
    height: 90px;
    width: 100vw;
    background: #fff;
    z-index: 15;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header ul {
    display: flex;
    list-style: none;
    gap: 10px;
    height: 100%;
}

header ul li {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

header ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fit-content;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    font-weight: 500;
    padding: 10px;

    &:hover {
        color: #DA70D6;
    }
}

.logo.for-phone {
    position: absolute;
    top: 8px;
    left: 20px;
    padding: 0;
    margin: 0;
    display: none;
    z-index: 10;
}

.logo.for-phone a {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0px;
}

#favicon-for-phone {
    width: 45px;
    margin-bottom: 6px;
}

.logo {
    display: flex;
    justify-content: center;
    text-align: center;
}

.logo a {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
}

#favicon {
    width: 44px;
    margin-bottom: 6px;
}

.logo img {
    width: 170px;
}

.quote-btn button {
    padding: 15px 30px;
    border-radius: 3px;
    border: 0;
    text-transform: uppercase;
    font-size: 1em;
    position: relative;
    overflow: hidden;
    display: none;
}


.services-preview,
.contact-preview {
    display: flex;
    position: absolute;
    background: #fff;
    top: 100%;
    min-height: 20vh;
    max-height: 0;
    min-width: 150px;
    opacity: 0;
    transform: translateY(20px);
    visibility: hidden;
    overflow: hidden;
    transition: max-height 0.1s ease-in-out, opacity 0.1s ease-in-out, transform 0.1s ease-in-out, padding 0.4s ease-in-out, visibility 0.4s ease-in-out;
    pointer-events: none;
    border: 1px solid #aaa;
    border-radius: 4px;
}

/* only laptop */
@media (min-width: 768px) {

    #service:hover .services-preview,
    #contact:hover .contact-preview {
        max-height: 1000px;
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        pointer-events: auto;
    }
}



.contacts {
    display: flex;
}

.contacts .title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    padding: 0 15px;
    text-transform: uppercase;
}

.contacts .numbers a{
    text-transform: lowercase;
}



li span {
    padding: 0 0 10px 0;
    transform: rotate(90deg);
}


.services-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 30px 20px 30px 10px;
    border-right: 1px solid gray;
    gap: 5px;
    align-items: left;
}

.services-menu a {
    display: flex;
    justify-content: start;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: start;
}

.services {
    padding: 30px 20px 30px 20px;
    background: #eee;
}

.services img {
    width: 40%;
    border-radius: 5px;
}

.inside-services {
    gap: 20px;
    flex-wrap: wrap;
    display: none;
}

.inside-services.visible {
    display: flex;
}

.inside-services a {
    width: 200px;
    height: 150px;
    background: #fff;
    border-radius: 5px;
    flex-direction: column;
    gap: 10px;

    &:hover {
        box-shadow: 4px 4px 8px #aaa, -4px -4px 8px #ddd;
    }
}

#bg-video {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    object-fit: cover;
    z-index: -2;
    user-select: none;
    pointer-events: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    background-color: rgba(106, 13, 173, 0.5);
    z-index: -1;
}

.overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -1;
}

.overlay-ham {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: none;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
}

.overlay-ham.active {
    display: block;
}


.ham {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    gap: 4px;
    display: none;
    z-index: 12;
    align-items: end;
    min-width: fit-content;
    left: calc(100vw + 75vw - 55px);
    top: 0;
    border-radius: 4px;
    padding: 20px 15px;
}

.ham.active {
    position: relative;
    left: 0;
    background: #fff;
    max-width: 100vw;
    width: 100vw;
    padding-right: 10px;
}

.ham.active .ham-line:first-child {
    transform: translateX(-20px) rotate(-45deg) translateY(2px);
    transform-origin: center;
    width: 11px;
}

.ham.active .ham-line:nth-child(2) {
    transform: translateX(-4px);
}

.ham.active .ham-line:last-child {
    transform: translateX(-20px) rotate(45deg) translateY(-2px);
    transform-origin: center;
    width: 11px;
}

.ham.active .ham-line {
    background: #000;
}

.ham-line {
    display: block;
    height: 2px;
    width: 25px;
    background: #fff;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    border-radius: 2px;
    border-bottom: 1px solid black;
}


/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {
    header a {
        font-size: 0.8em;
    }

    .quote-btn button {
        font-size: 0.8em;
    }

    .logo img {
        width: 140px;
    }

    .quote-btn button {
        padding: 10px 20px;
    }

    .inside-services a {
        width: 150px;
        height: 100px;
    }
}

/* Mobile and mobile landscape */
@media (max-width: 768px),
(orientation: landscape) and (max-width: 850px) {

    .logo {
        padding: 10px 0;
    }

    .logo.for-phone {
        display: block;
    }

    .ham {
        display: flex;
    }

    header {
        position: fixed;
        top: 0;
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: auto;
        max-height: 80vh;
        padding: 0 0 10px 0;
        width: 75vw;
        background: #fff;
        z-index: 11;
        box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);
        transform: translateX(-100%);
        border-bottom-right-radius: 4px;
    }

    header.active {
        transform: translateX(0%);
    }

    header ul {
        flex-direction: column;
        align-items: start;
        padding: 0;
        width: 100%;
        gap: 0;
        overflow-x: hidden;
        overflow-y: auto;
    }

    header ul li {
        width: 100%;
        text-align: left;
        align-items: start;
    }

    header ul li a {
        padding: 20px 20px;
        width: 100%;
        display: flex;
        justify-content: start;
        height: 100%;
        border-radius: 4px;

        &:hover,
        &:focus {
            color: #000;
            background: #ddd;
        }
    }

    .contact-preview {
        display: none;
    }

    .services-preview {
        transform: translateY(0px);
        top: 0;
        max-height: 0;
        min-height: 0;
        margin-left: 50px;
        transition: opacity 0 ease-in-out, max-height 0 ease-out, min-height 0 ease-out;
        border: 0;
        position: relative;
        width: 100%;
    }

    .services-preview.active {
        display: flex;
        visibility: visible;
        opacity: 1;
        min-height: 150px;
        max-height: 250;
        pointer-events: auto;
    }

    .services-menu {
        border-right: 0;
        padding: 10px;
        transition: all 0.3s ease;
        top: 0;
        width: 100%;
    }


    .quote-btn button {
        display: none;
    }

    li span {
        display: none;
    }

    #service span {
        display: block;
    }

    .logo a {
        margin-left: -15px;
    }
}