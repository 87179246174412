.HomeInsights {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 70px 40px;
    background: #e6dbee;
    margin: 40px 0 110px 0;
}

.insight {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.insight img {
    width: 100px;
}

.insight:last-child img{
    width: 90px;
}

.insight b {
    font-size: 3.5em;
    font-weight: 500;
}

.insight p {
    font-size: 2em;
}

.count-up {
    transition: all 1s ease-in-out;
}

/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {
    .HomeInsights {
        padding: 70px 30px;
    }
    
    .insight {
        gap: 15px;
        padding: 10px;
    }
    
    .insight img {
        width: 70px;
    }
    
    .insight:last-child img{
        width: 60px;
    }
    
    .insight b {
        font-size: 2.5em;
        font-weight: 600;
    }
    
    .insight p {
        font-size: 1.3em;
    }
}

/* Mobile */
@media (max-width: 768px) {
    .HomeInsights {
        grid-template-columns: repeat(2, 1fr);
        padding: 50px 10px;
    }
    
    .insight {
        gap: 25px;
    }
    
    .insight img {
        width: 50px;
    }
    
    .insight:last-child img{
        width:40px;
    }
    
    .insight b {
        font-size: 1.5em;
        font-weight: 600;
    }
    
    .insight p {
        font-size: 1em;
    }
}