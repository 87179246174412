.core-services {
    width: 90%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    gap: 30px;
    color: #000;
    height: fit-content;
    min-height: 400px;
    flex-wrap: wrap;
}

.service {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    color: #000;
    border: 1px solid #6a0dad52;
    min-height: 330px;
    height: 70%;
    box-shadow: 0 4px 10px 0 #6a0dad52, 0 6px 15px 0 #a01aff4e;
    width: 100%;
    min-width: 200px;
    text-align: justify;
}

.service p {
    font-weight: 400;
}

.service h3 {
    color: #6A0DAD;
}

.service img {
    width: 100px;
}

/* Mobile */
@media (max-width: 768px) {
    .core-services {
        width: 95%;
    }
}
    