.WebDevelopment {
    position: absolute;
    min-height: 100vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    color: #fff;
    gap: 0px;
    height: fit-content;
}

.web-development .benifit{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: lighter;
}

.web-development .FourBenifits.second .benifit:nth-child(even){
    background: #6A0DAD;
    color: #fff;
}
.web-development .FourBenifits.first .benifit:nth-child(odd){
    background: #6A0DAD ;
    color: #fff;
}

.web-development .FourBenifits.second{
    margin-bottom: 100px;
}

.web-development .heading-cover{
    background: #dfcbed;
    padding: 50px 0 70px 0;
    color: #000;
    margin-bottom: 170px;
}

.tech-cover .Technologies{
    margin: 20px auto 20px auto;
}

/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {
    .web-development .benifits{
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .web-development .benifit{
        min-width: 190px;
    }
}