/* Header.module.css */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: rgba(247, 249, 252, 0.9);
    backdrop-filter: blur(8px);
    transition: transform 0.3s ease;
}

.headerHidden {
    transform: translateY(-100%);
}

.headerContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1a365d;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.logo span {
    color: #142952;
    margin-left: 2px;
}

.menuButton {
    display: none;
    background: none;
    border: none;
    color: #1a365d;
    cursor: pointer;
    padding: 0.75rem;
    border-radius: 8px;
}

.menuButton:hover {
    background: rgba(26, 54, 93, 0.1);
}

.nav {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.navList {
    display: flex;
    gap: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navLink {
    color: #4a5568;
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem;
    transition: all 0.3s ease;
    position: relative;
}

.navLink:hover {
    color: #1a365d;
}

.navLink.active {
    color: #1a365d;
}

.navLink.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #142952;
    border-radius: 2px;
}

.authButtons {
    display: flex;
    gap: 1rem;
}

.loginButton {
    color: #1a365d;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.loginButton:hover {
    background: rgba(26, 54, 93, 0.1);
}

.signupButton {
    background: #1a365d;
    color: #f7f9fc;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.signupButton:hover {
    background: #142952;
}

@media (max-width: 1024px) {
    .navList {
        gap: 1.5rem;
    }
}

@media (max-width: 768px) {
    .headerContent {
        padding: 0.75rem 1.5rem;
    }

    .menuButton {
        display: block;
    }

    .nav {
        position: fixed;
        top: 72px;
        left: 0;
        right: 0;
        background: #f7f9fc;
        padding: 1.5rem;
        flex-direction: column;
        align-items: stretch;
        gap: 1.5rem;
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    }

    .navOpen {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    .navList {
        flex-direction: column;
        gap: 1.5rem;
    }

    .navLink {
        display: block;
        padding: 0.75rem;
    }

    .authButtons {
        flex-direction: column;
        gap: 1rem;
    }

    .loginButton,
    .signupButton {
        text-align: center;
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .headerContent {
        padding: 0.75rem 1rem;
    }

    .logo {
        font-size: 1.25rem;
    }
}