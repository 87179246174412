/* HeroSection.module.css */
.heroSection {
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.backgroundSplit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.leftBackground {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #142952;
}

.rightBackground {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: #f7f9fc;
}

.heroContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 6rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
    position: relative;
    z-index: 1;
}

.heroText {
    color: white;
}

.heroText h1 {
    font-size: 3.5rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    font-weight: 700;
    background: linear-gradient(90deg, #ffffff 0%, #e2e8f0 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.heroText p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    opacity: 0.9;
    line-height: 1.6;
}

.heroButtons {
    display: flex;
    gap: 1rem;
}

.primaryButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    background: white;
    color: #1a365d;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    transition: transform 0.3s ease;
    border: none;
    cursor: pointer;
}

.primaryButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.demoButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.demoButton:hover {
    background: rgba(255, 255, 255, 0.2);
}

.carouselContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
}

.carousel {
    position: relative;
    width: 100%;
    padding-top: 66.67%;
    /* 3:2 Aspect Ratio */
    background: white;
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.carouselImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    padding: 1rem;
}

.carouselImage.active {
    opacity: 1;
}

.indicatorsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
}

.indicators {
    display: flex;
    gap: 0.5rem;
}

.indicator {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: rgba(26, 54, 93, 0.3);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 0;
}

.indicator:hover {
    background: rgba(26, 54, 93, 0.5);
}

.activeIndicator {
    background: #1a365d;
}

@media (max-width: 768px) {
    .heroContent {
        grid-template-columns: 1fr;
        text-align: center;
        gap: 2rem;
    }

    .heroText h1 {
        font-size: 2.5rem;
    }

    .heroButtons {
        justify-content: center;
        flex-direction: column;
    }

    .backgroundSplit {
        display: none;
    }

    .heroSection {
        background: #1a365d;
    }

    .carousel {
        padding-top: 75%;
        /* 4:3 Aspect Ratio for mobile */
    }
}