.footer {
    background: #1a202c;
    color: white;
    padding: 4rem 2rem 2rem;
}

.footerContent {
    max-width: 1200px;
    margin: 0 auto;
}

.footerMain {
    display: grid;
    grid-template-columns: 1.5fr 2.5fr;
    gap: 4rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.footerBrand h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    letter-spacing: -0.5px;
}

.footerBrand p {
    color: #a0aec0;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    font-size: 0.9375rem;
}

.socialLinks {
    display: flex;
    gap: 0.75rem;
}

.socialLink {
    color: #a0aec0;
    background: rgba(255, 255, 255, 0.06);
    padding: 0.625rem;
    border-radius: 6px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialLink:hover {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    transform: translateY(-1px);
}

.footerLinks {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

.linkGroup h4 {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 1.25rem;
    color: #e2e8f0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.linkGroup ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.linkGroup li {
    margin-bottom: 0.75rem;
}

.linkGroup a {
    color: #a0aec0;
    text-decoration: none;
    transition: all 0.2s ease;
    font-size: 0.9375rem;
    display: inline-block;
}

.linkGroup a:hover {
    color: white;
}

.footerBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    color: #718096;
    font-size: 0.875rem;
}

.footerMeta {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.footerMeta a {
    color: #718096;
    text-decoration: none;
    transition: color 0.2s ease;
}

.footerMeta a:hover {
    color: #a0aec0;
}

.divider {
    color: #2d3748;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .footerLinks {
        grid-template-columns: repeat(2, 1fr);
        gap: 2.5rem;
    }

    .footerMain {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
}

@media (max-width: 768px) {
    .headerContent {
        padding: 0.75rem 1.5rem;
    }

    .menuButton {
        display: block;
    }

    .nav {
        position: fixed;
        top: 64px;
        left: 0;
        right: 0;
        background: #1a202c;
        padding: 1.5rem;
        flex-direction: column;
        align-items: stretch;
        gap: 1.5rem;
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
    }

    .navOpen {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    .navList {
        flex-direction: column;
        gap: 0.75rem;
    }

    .navLink {
        padding: 0.75rem;
    }

    .authButtons {
        flex-direction: column;
        gap: 0.75rem;
    }

    .loginButton,
    .signupButton {
        text-align: center;
        padding: 0.75rem;
    }

    .footer {
        padding: 3rem 1.5rem 1.5rem;
    }

    .footerBottom {
        flex-direction: column;
        gap: 1.5rem;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .headerContent {
        padding: 0.75rem 1rem;
    }

    .logo {
        font-size: 1.25rem;
    }

    .footer {
        padding: 2rem 1rem 1rem;
    }

    .footerLinks {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .footerMeta {
        flex-direction: column;
        gap: 1rem;
    }

    .divider {
        display: none;
    }
}