
.FourBenifits{
    width: 90%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: #000;
    height: fit-content;
    flex-wrap: wrap;
    background: #fff;
}

.benifits img {
    width: 50px;
}

.benifits {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    justify-content: center;
    gap: 10px;
    height: fit-content;
    flex-wrap: wrap;
    width: 100%;
}

.benifit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    padding: 30px 15px;
    border-radius: 7px;
    width: 23%;
    min-width: 270px;
    box-shadow: 0 4px 10px 0 #6a0dad52, 0 6px 15px 0 #a01aff4e;
    margin: 0 auto;
}


/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {
    .FourBenifits {
        margin-top: 30px;
    }
}

/* Mobile */
@media (max-width: 768px) {
    .FourBenifits {
        margin-top: 0px;
    }
/* 
    .heading {
        padding: 5px;
        width: 100%;
    } */
    .benifit {
        width: 100%;
        min-width: 270px;
    }
    .benifits {
        margin-bottom: 40px;
    }

}

/* Mobile Landscape */
@media (orientation: landscape) and (max-width: 768px) {
    /* Styles for mobile devices in landscape orientation */
}