.Image_Details {
    width: 90%;
    margin: auto;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    justify-content: center;
    color: #000;
    height: fit-content;
    min-height: 400px;
    flex-wrap: wrap;
    background: #fff;
    margin-top: 70px;
}

.Image_Details>div {
    min-width: 250px;
}

.image-and-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
    transform: translateX(150px);
    text-align: justify;
}

.image-and-details.reverse {
    transform: translateX(-150px);
}

.flex {
    display: flex;
    transform: translateX(-150px);
    background: #fff;
    z-index: 9;
}

.flex.reverse {
    transform: translateX(150px);
}

.flex{
    opacity: 1;
    transition: transform 0.6s ease-out, opacity 1s ease-out;
}
.image-and-details {
    opacity: 0;
    transition: transform 0.6s ease-out, opacity 1s ease-out;
}

.flex.in-view,
.image-and-details.in-view {
    transform: translateX(0);
    opacity: 1;
}

.Image_Details img {
    position: relative;
    width: 80%;
    margin: auto;
}

.Image_Details p {
    line-height: 25px;
}

.Image_Details h1{
    text-align: left;
}

/* Mobile */
@media (max-width: 768px) {
    .Image_Details>div {
        min-width: 150px;
    }


    .Image_Details {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}