

.Join{
    position: absolute;
    min-height: 100vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    color: #fff;
    gap: 0px;
    height: fit-content;
}

