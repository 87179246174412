


.Bottom{
    background: #130013;
    padding: 50px 30px;
    gap: 10px;
}

/* Mobile */
@media (max-width: 768px) {
    .Bottom{
        padding: 30px 10px;
        gap: 10px;
        font-size: 0.8em;
        display: flex;
        flex-direction: column;
    }
    
}
