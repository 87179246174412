.legal-container {
    min-height: 100vh;
    padding: 80px 20px;
    background: linear-gradient(135deg, rgba(160, 26, 255, 0.05) 0%, rgba(106, 13, 173, 0.05) 100%);
}

.legal-content {
    max-width: 800px;
    margin: 0 auto;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.legal-content h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
}

.legal-section {
    margin-bottom: 30px;
}

.legal-section h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.legal-section p {
    color: #333;
    line-height: 1.6;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .legal-content {
        padding: 20px;
    }

    .legal-content h1 {
        font-size: 2rem;
    }

    .legal-section h2 {
        font-size: 1.25rem;
    }
}

@media (max-width: 480px) {
    .legal-container {
        padding: 60px 10px;
    }

    .legal-content h1 {
        font-size: 1.75rem;
    }
}