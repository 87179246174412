.JoinForm {
    height: 100vh;
    z-index: 10;
    color: #000;
    flex-direction: column;
    height: fit-content;
}

.join-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    padding: 30px;
    border-radius: 4px;
    border: 1px solid #6a0dad52;
    box-shadow: 0 4px 10px 0 #6a0dad52, 0 6px 15px 0 #a01aff4e;
}

.form-content {
    width: 90%;
    margin: 30px auto 100px auto;
    flex-direction: column;
    gap: 40px;
    font-weight: 200;
    letter-spacing: 1px;
}

.personal-info,
.general-data {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
}


input,
select {
    padding: 11px;
    border-radius: 3px;
    background: #eee;
    outline: none;
    border: none;
}

form div>div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

form div>div label {
    text-align: left;
    font-weight: 500;
    margin: 5px 0;
    margin-left: 2px;
}

.JoinForm h3 {
    color: #fff;
    text-align: left;
    padding: 11px;
    background: #6A0DAD;
    border-radius: 3px;
    margin: 20px 0;
}

.submit-button {
    text-align: right;
    padding-top: 10px;
}

.submit-button button {
    margin-top: 15px;
    padding: 15px 30px;
    cursor: pointer;
    text-transform: uppercase;
    border: 0;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    font-weight: 400;
}

/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {
    .submit-button button {
        padding: 12px 25px;
        font-size: 0.9em;
    }
}

/* Mobile */
@media (max-width: 768px) {
    .submit-button button {
        padding: 13px 20px;
        font-size: 0.8em;
    }

    .personal-info,
    .general-data {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }
}

/* Mobile Landscape */
@media (orientation: landscape) and (max-width: 768px) {
    /* Styles for mobile devices in landscape orientation */
}