.pricingSection {
    padding: 6rem 2rem;
    background: #f7fafc;
}

.pricingSection h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #1a365d;
    margin-bottom: 1rem;
}

.subtitle {
    text-align: center;
    color: #4a5568;
    margin-bottom: 3rem;
    font-size: 1.1rem;
}

.pricingCards {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 0.1rem;
}

.pricingCard {
    background: white;
    border-radius: 16px;
    padding: 2rem;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #e2e8f0;
    display: flex;
    flex-direction: column;
}

.pricingCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.featured {
    border: 2px solid #1a365d;
    box-shadow: 0 4px 20px rgba(26, 54, 93, 0.15);
}

.popularBadge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: #1a365d;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.875rem;
    font-weight: 500;
    min-width: 150px;
}

.pricingCard h3 {
    font-size: 1.5rem;
    color: #1a365d;
    margin-bottom: 1rem;
    text-align: center;
}

.priceContainer {
    text-align: center;
    margin-bottom: 2rem;
}

.price {
    font-size: 3rem;
    font-weight: 700;
    color: #1a365d;
    text-align: center;
    margin-bottom: 0.5rem;
}

.price span {
    font-size: 1rem;
    font-weight: 400;
    color: #4a5568;
}

.yearlyPrice {
    font-size: 0.9rem;
    color: #4a5568;
    margin-top: 0.5rem;
}

.savingsBadge {
    display: inline-block;
    background: #c6f6d5;
    color: #22543d;
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-size: 0.75rem;
    margin-left: 0.5rem;
    font-weight: 500;
}

.pricingCard ul {
    list-style: none;
    padding: 0;
    margin: 0 0 2rem 0;
    flex-grow: 1;
}

.pricingCard li {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 0.75rem 0;
    color: #4a5568;
    line-height: 1.4;
}

.pricingCard li svg {
    color: #1a365d;
    flex-shrink: 0;
    margin-top: 0.2rem;
}

.priceButton {
    display: block;
    width: 100%;
    padding: 1rem;
    background: #1a365d;
    color: white;
    text-align: center;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    transition: background 0.3s ease;
    border: none;
    cursor: pointer;
}

.priceButton:hover {
    background: #142952;
}

@media (max-width: 1024px) {
    .pricingCards {
        grid-template-columns: repeat(2, 1fr);
        max-width: 800px;
    }

    .pricingCard:last-child {
        grid-column: span 2;
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .pricingSection {
        padding: 4rem 1rem;
    }

    .pricingCards {
        grid-template-columns: 1fr;
        max-width: 400px;
    }

    .pricingCard:last-child {
        grid-column: span 1;
    }

    .pricingSection h2 {
        font-size: 2rem;
    }

    .subtitle {
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}